import { useThrowSession } from "@/components/context/ThrowSessionProvider";
import Simulator from "@/components/Simulator";
import useLocalStorage from "@/hooks/useLocalStorage";
import { useUser } from "@/hooks/useUser";
import { useUserMetadata } from "@/hooks/useUserMetadata";
import { Alert, AlertTitle, Link, Paper, Stack } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useUnityContext } from "@/hooks/useUnityContext";
export const StrokePlay = () => {
  const [{ user, userId }] = useUser();
  const [acknowledged, setAcknowledged] = useLocalStorage("display-name-acknowledged", "false");
  const [userMetadata, userMetadataLoading] = useUserMetadata(userId);
  const { throws } = useThrowSession();
  const unityContext = useUnityContext({
    buildNumber: process.env.STROKE_PLAY_VERSION ?? process.env.SIMULATOR_VERSION,
  });
  return (
    <Stack gap={4} sx={{ position: "relative", alignItems: "center" }}>
      <AnimatePresence mode="wait">
        {acknowledged === "false" && user && !userMetadataLoading && !userMetadata?.name && (
          <Alert
            key="leaderboard-alert"
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            severity="info"
            onClose={() => setAcknowledged("true")}
            sx={{ width: "fit-content" }}
            elevation={1}
          >
            <AlertTitle sx={{ mb: 1 }}>Leaderboards</AlertTitle>
            To claim your spot on the leaderboards, enter a display name in your{" "}
            <Link href={`/settings`}>profile settings</Link>.
          </Alert>
        )}
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            aspectRatio: 16 / 9,
          }}
          elevation={3}
        >
          <Simulator
            context={unityContext}
            mode="single"
            userId={userId}
            flights={throws}
            strokePlayBadge={
              window.location.host.includes("techdisc.com") ? "productionSP" : "develop"
            }
          />
        </Paper>
      </AnimatePresence>
    </Stack>
  );
};

// Display Name Overlay
// {user && !userMetadataLoading && userMetadata?.name && (
//   <Box
//     sx={{
//       position: "absolute",
//       top: 0,
//       left: 0,
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "flex-start",
//       width: "100%",
//       aspectRatio: 16 / 9,
//       zIndex: 100,
//       background: "rgba(0,0,0,0.3)",
//       backdropFilter: "blur(5px)",
//       p: 8,
//     }}
//   >
//     <Paper elevation={3} sx={{ p: 2 }}>
//       <FormControl fullWidth>
//         <FormLabel htmlFor="name" sx={{ mb: 1 }}>
//           Set Your Display Name
//         </FormLabel>
//         <TextField id="name" size="small" />
//       </FormControl>
//     </Paper>
//   </Box>
// )}
