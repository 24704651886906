import { getUserDiscPresets } from "@/firebase/collections";
import { useUser } from "@/hooks/useUser";
import type { DiscPreset } from "@/model/discs";

import { getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";

export const useDiscCollection = () => {
  const [collectionLoading, setCollectionLoading] = useState(true);
  const [discCollection, setDiscCollection] = useState<DiscPreset[] | null>(null);
  const [{ trueUserId }] = useUser();

  useEffect(() => {
    async function getDiscs() {
      if (!trueUserId) {
        setCollectionLoading(false);
        return;
      }
      const docs = await getDocs(getUserDiscPresets(trueUserId));
      const discs = docs.docs.map((doc) => ({ ...doc.data(), id: doc.id }) as DiscPreset);
      // remove keys with null values
      discs.forEach((disc) => {
        Object.keys(disc).forEach((key) => {
          if (disc[key] === null) {
            delete disc[key];
          }
        });
      });

      setDiscCollection(discs);
      setCollectionLoading(false);
    }
    getDiscs();
  }, [trueUserId]);

  return [discCollection, collectionLoading] as const;
};
