import { isAfter, isBefore, isEqual } from "date-fns";
import type { Timestamp } from "firebase/firestore";

export interface EventTiming {
  previewDate?: Timestamp;
  startDate: Timestamp;
  endDate: Timestamp;
}

export type EventStatus = {
  isLive: boolean;
  isPast: boolean;
  isPreview: boolean;
};

export const addEventStatus = <T extends EventTiming>(
  event: T,
  isManager?: boolean,
): T & EventStatus => {
  const now = new Date();
  const startDate = isManager
    ? new Date(event.startDate.toDate().getTime() - 14 * 24 * 60 * 60 * 1000)
    : event.startDate.toDate();
  const endDate = event.endDate.toDate();
  const previewDate = isManager ? startDate : (event.previewDate?.toDate() ?? startDate);

  const isLive = isAfter(now, startDate) && isBefore(now, endDate);
  const isPreview = isAfter(now, previewDate) && isBefore(now, startDate);
  const isPast = isAfter(now, endDate) || isEqual(now, endDate);

  return {
    ...event,
    isLive,
    isPreview,
    isPast,
  };
};
