import { useUser } from "@/hooks/useUser";
import { useEntitlements } from "@/stripe";

import type { SimulatorCourseHole } from "@/types/simulator";
import { getUserPermissions } from "@/utils/auth";
import { useEffect, useState } from "react";
import { useLiveAndPlayEvents } from "@/components/layout/UnauthenticatedBanner";

export const useSimulatorPermissions = () => {
  const [loading, setLoading] = useState(true);
  const [allowedCourses, setAllowedCourses] = useState<
    { id?: string; displayName?: string; holes?: SimulatorCourseHole[] }[] | null | undefined
  >(null);
  const [allowedLayouts, setAllowedLayouts] = useState<
    { id?: string; displayName?: string }[] | null | undefined
  >(null);
  const [{ user, trueUserId, userLoading }] = useUser();

  const { entitlements, loading: entitlementsLoading } = useEntitlements(user, userLoading);
  const { playEvents, loading: eventLoading } = useLiveAndPlayEvents();

  useEffect(() => {
    if (userLoading || entitlementsLoading || eventLoading) {
      return;
    }
    if (!loading) {
      return;
    }

    let cancelled = false;
    async function getPermissions() {
      try {
        const permissions = await getUserPermissions(trueUserId, entitlements, playEvents);
        if (cancelled) {
          return;
        }
        setAllowedCourses(permissions?.allowedCourses);
        setAllowedLayouts(permissions?.allowedLayouts);
      } finally {
        if (!cancelled) {
          setLoading(false);
        }
      }
    }
    getPermissions();
    return () => {
      cancelled = true;
    };
  }, [
    trueUserId,
    entitlements,
    userLoading,
    entitlementsLoading,
    playEvents,
    eventLoading,
    loading,
  ]);

  return [{ allowedCourses, allowedLayouts }, loading] as const;
};
