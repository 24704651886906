import { PlayEventBanner } from "@/components/banners/PlayEventBanner";
import { getLiveEventsCollection, getPlayEventsCollection } from "@/firebase/collections";
import { addEventStatus, EventStatus } from "@/utils/events";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { TechDiscBanner } from "../../dashboard/ShopBanner";
import useLive from "../../hooks/useLive";
import { useUser } from "../../hooks/useUser";
import { LiveBanner } from "../banners/LiveBanner";
import { useGlobal } from "@/components/providers/GlobalProvider";
import { LiveEvent, LiveEventDocument } from "@/firebase/converters/events";
import { useMemo } from "react";

export function useLiveAndPlayEvents() {
  const [{ user, userLoading }] = useUser();
  const [liveEventDocs = [], liveEventsLoading] = useCollectionData(getLiveEventsCollection());
  const [playEventDocs = [], playEventsLoading] = useCollectionData(getPlayEventsCollection());
  const { isManager } = useGlobal();

  return useMemo(() => {
    const liveEvents: (LiveEvent & EventStatus)[] = liveEventDocs.map((e) =>
      addEventStatus(e, isManager),
    );

    const playEvents = playEventDocs.map((e) => addEventStatus(e, isManager));
    return {
      liveEvents,
      playEvents,
      loading: liveEventsLoading || playEventsLoading || userLoading,
      user,
    };
  }, [
    liveEventDocs,
    playEventDocs,
    liveEventsLoading,
    playEventsLoading,
    userLoading,
    user,
    isManager,
  ]);
}

export default function UnauthenticatedBanner() {
  const { isLiveRoute } = useLive();
  const { liveEvents, playEvents, loading, user } = useLiveAndPlayEvents();

  const isLiveEventLiveOrUpcoming = liveEvents?.some((event) => event.isLive || event.isPreview);
  const isPlayEventLiveOrUpcoming = playEvents?.some((event) => event.isPreview || event.isLive);

  if (loading) {
    return null;
  } else if (isLiveRoute || isLiveEventLiveOrUpcoming) {
    return <LiveBanner liveEvents={liveEvents} />;
  } else if (isPlayEventLiveOrUpcoming) {
    return <PlayEventBanner playEvents={playEvents} />;
  } else {
    return !user ? <TechDiscBanner /> : null;
  }
}
